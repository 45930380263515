<template>
  <a-layout class="h100">
    <a-layout-header class="flex a-c j-sb">
      <div class="flex a-c flex-1">
        <div class="logoImg">
          <img
            :src="require('@/assets/image/logo/' + logo_img_src + '.png')"
            alt=""
            class="mr-18"
          />{{ webtitle }}
        </div>
        <headerBar></headerBar>
      </div>
      <adminHeaderRight v-if="isAdmin === '1'"></adminHeaderRight>
      <headerRight v-else></headerRight>
    </a-layout-header>
    <a-layout-content>
      <a-layout
        class="h100"
        :class="{ noFlex: route.name === 'Home' }"
        style="overflow: auto"
      >
        <a-layout-sider v-if="leftMenus.length !== 0" :width="220">
          <p class="silderTitle">{{ $t(silderTitle) }}</p>
          <leftMenu :list="leftMenus" :activePath="slectChildren"></leftMenu>
        </a-layout-sider>
        <a-layout-content class="h100" style="overflow: auto">
          <div
            class="breadBox pl-20"
            :class="{ 'mb-0': route.name === 'batchdefense' }"
            v-if="leftMenus.length !== 0"
          >
            <span
              class="backIcon"
              @click.stop="
                $router.push({
                  path: route.meta.parentMenu
                    ? route.meta.parentMenu
                    : route.meta.activeMenu,
                })
              "
              v-if="route.meta.parentMenu"
              ><ArrowLeftOutlined
            /></span>
            <a-breadcrumb>
              <a-breadcrumb-item>
                <router-link :to="String(route.meta.activeMenu)">
                  {{ route.matched[1].meta.title }}
                </router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item v-if="route.name === 'batchdefense'">
                <router-link :to="String(route.meta.parentMenu)">
                  {{ route.matched[2].meta.title }}
                </router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item>{{ route.meta.title }}</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
          <!-- :class="[leftMenus.length !== 0 ? route.name === 'batchdefense' ? 'leftBatchBox' : 'leftMainBox' : 'contentMainBox' ]" -->
          <!-- <div  :style="{height: leftMenus.length !== 0 ? route.name === 'batchdefense' ? 'calc(100% - 54px)' : 'calc(100% - 74px)' : '100%'}" :class="{'pl-20 pr-20 overflowAuto' : leftMenus.length !== 0}"> -->
          <div
            :class="[
              leftMenus.length !== 0
                ? route.name === 'batchdefense'
                  ? 'leftBatchBox'
                  : 'leftMainBox'
                : 'contentMainBox',
            ]"
          >
            <div v-if="notConnectArr.indexOf(imgName) > -1 && isnoHaveDomain">
              <a-alert type="warning" show-icon class="mb-16">
                <template #message>
                  <span style="font-size: 12px; color: #f15700"
                    >未配置域名，请前往域名列表页面配置域名，再访问此页面。</span
                  >
                  <span
                    class="mainC mainClick fs-12 fw-500"
                    @click.stop="$router.push({ path: '/domainlist' })"
                    >去配置</span
                  >
                </template>
              </a-alert>
              <div class="notConnectBox p-24 posiR">
                <div class="funcExample">
                  <i class="iconfont icon-gongnengguanli1"></i>
                  功能示例
                </div>
                <img
                  :src="
                    require('@/assets/image/notconnect/' + imgName + '.png')
                  "
                  alt=""
                />
              </div>
            </div>
            <div v-else :class="{ h100: !isDashboard }">
              <dashboardHeader v-if="isDashboard"></dashboardHeader>
              <router-view></router-view>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout-content>
  </a-layout>
</template>
<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from "vue";
import headerBar from "./components/headerBar.vue";
import headerRight from "./components/headerRight.vue";
import adminHeaderRight from "./components/adminHeaderRight.vue";
import AppMain from "./components/AppMain.vue";
import leftMenu from "./components/leftMenu.vue";
import { ArrowLeftOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import { formatMenus } from "./components/helper";
import { mockMenu } from "@/hook/menu";
import dashboardHeader from "@/components/dashboardHeader/index.vue";
import { emitter } from "@/utils/bus";
import { getAllRecord } from "@/api/domainConfig";
import { getDomainTreeData } from "@/api/dashboard";

const route = useRoute();
const logo_img_src = ref("logoIcon");
if (localStorage.getItem("customImg")) {
  const customImg = localStorage.getItem("customImg");
  logo_img_src.value = customImg || "logoIcon";
}
const webtitle = computed(() => {
  return localStorage.getItem("isAdmin") === "1"
    ? "多租户管理"
    : localStorage.getItem("customTitle")
    ? localStorage.getItem("customTitle")
    : "弈盾";
});
const isAdmin = localStorage.getItem("isAdmin");
const imgName = computed(() => {
  return route.name;
}); // 未接入时的图片
// 'cache'放到上面导航了
const notConnectArr = [
  "basicsecurity",
  "Certificate",
  "DashboardAttack",
  "DashboardInterview",
  "DashboardSafety",
  "general",
  "ipList",
];
const isnoHaveDomain = ref(true);

const isInitTree = ref(true); // 只请求一次getDomainTreeData
const isInitConfig = ref(true); // 只请求一次getAllRecord
const getHaveDomain = () => {
  if (notConnectArr.indexOf(route.name) > -1) {
    if (!isInitTree.value) return;
    if (
      notConnectArr[notConnectArr.indexOf(route.name)].indexOf("Dashboard") > -1
    ) {
      getDomainTreeData({ is_beian: 1, status: 1 }).then((res: any) => {
        if (res.retcode === 0) {
          isnoHaveDomain.value = !res.data;
          isInitTree.value = false;
        }
      });
    } else {
      if (!isInitConfig.value) return;
      getAllRecord({ is_beian: 1, status: 1 }).then(async (res: any) => {
        if (res?.retcode === 0) {
          isnoHaveDomain.value = res.data.length === 0;
          isInitConfig.value = false;
        }
      });
    }
  }
};
getHaveDomain();

const menu_data = ref([]);
menu_data.value = formatMenus(mockMenu);
const silderTitle = ref("");
const leftMenus = computed(() => {
  const findChildren =
    menu_data.value.find((item) => {
      silderTitle.value = item.name;
      return route.path.startsWith(item.path);
    })?.children || [];
  return formatMenus(findChildren);
});
const slectChildren = ref("");
slectChildren.value = route.meta.parentMenu
  ? route.meta.parentMenu
  : route.path;

const isDashboard = ref(false);
isDashboard.value = route.name.indexOf("Dashboard") > -1;

watch(route, (val) => {
  if (
    val.meta.activeMenu === "/domainconfig" ||
    val.meta.activeMenu === "/dashboard"
  ) {
    getHaveDomain();
  }
  if (val.path.indexOf("/domainconfig/") === -1) {
    localStorage.removeItem("hostIdValue");
    localStorage.removeItem("hostRecordValue");
  }
  isDashboard.value = val.name.indexOf("Dashboard") > -1;
});
</script>

<style lang="less" scoped>
.logoImg {
  // width:208px;
  width: v-bind("isAdmin === '1' ? '208px': '148px'");
  padding: 0px 20px;
  margin-right: 20px;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  color: var(--colorPrimary);
  & > img {
    height: 40px;
  }
}
.silderTitle {
  padding: 15px 24px;
  font-size: 14px;
  color: var(--color-4);
}
.noFlex {
  display: block;
}
.leftBatchBox {
  height: calc(100% - 54px);
  padding: 0 20px;
  overflow: auto;
}
.leftMainBox {
  height: calc(100% - 54px);
  padding: 0 20px 10px;
  overflow: auto;
}
.contentMainBox {
  height: 100%;
}
.notConnectBox {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  & > img {
    width: 100%;
  }
  .funcExample {
    border-radius: 0px 4px 0px 40px;
    background: #fff0e6;
    height: 42px;
    width: 128px;
    color: #ff6a00;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont {
      font-size: 22px;
      margin-right: 4px;
    }
  }
}
</style>
